import React, { useState } from "react";
import { Box, Flex, Text, Input, Button, Link, useToast, Image } from "@chakra-ui/react";
import axios from "axios";
import { FaInstagram } from "react-icons/fa";

const NewsletterPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axios.post("/api/subscribe", { email });

      if (response.data.success) {
        toast({
          title: "Thank you for signing up!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setEmail("");
      } else {
        throw new Error(response.data.message || "Subscription failed");
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Flex direction="column" alignItems="center" justifyContent="center" minHeight="100vh" bg="white">
      <Box mb={8}>
        <Image src="/illustrations/not-your-ordinary.gif" alt="Not Your Ordinary" width={200} height={154} />
      </Box>

      <Text fontSize="xl" fontWeight="bold" mb={4}>
        SPRING SUMMER 24 COMING SOON
      </Text>

      <form onSubmit={handleSubmit}>
        <Flex>
          <Input
            type="email"
            placeholder="YOUR EMAIL"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            borderColor="black"
            borderRadius="0"
            mr={2}
          />
          <Button type="submit" bg="black" color="white" borderRadius="0" isLoading={isSubmitting}>
            JOIN
          </Button>
        </Flex>
      </form>

      <Link href="https://www.instagram.com/lucidwear.io" isExternal mt={4}>
        <FaInstagram size={24} color="black" />
      </Link>
    </Flex>
  );
};

export default NewsletterPage;
