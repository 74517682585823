import { ChakraProvider, Box, theme } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./containers/LandingPage";
import HonorariesPage from "./containers/HonorariesPage";
import { useState } from "react";
import WalletCheckerPage from "./containers/WalletCheckerPage";
import NewsletterPage from "./containers/NewsletterPage";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Box height="100%" width="fit-content" minW="100%">
        <Router>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/honoraries" element={<HonorariesPage />} />
            <Route path="/walletchecker" element={<WalletCheckerPage />} />
            <Route path="/password" element={<NewsletterPage />} />
          </Routes>
        </Router>
      </Box>
    </ChakraProvider>
  );
};
